<template>
  <!-- 查看学生名单 -->
  <div class="closeStudentListUrl">
    <!-- <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'closeStudentListUrl'"> -->
        <div class="head_button">
          <!-- <el-button type="primary" @click="flagEvent('all')">全部</el-button>
          <el-button type="primary" @click="flagEvent('error')"
            >错误数据</el-button
          >
          <el-button type="primary" @click="flagEvent('right')"
            >正确数据</el-button
          > -->
          <el-tabs v-model="activeName" @tab-click="flagEvent">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane label="错误数据" name="error"></el-tab-pane>
            <el-tab-pane label="正确数据" name="right"></el-tab-pane>
          </el-tabs>
          <el-button type="primary" @click="fileUploadEvent" v-if="$route.query.type == 'edit'"
            >重新导入学生名单</el-button
          >
          <el-button v-allow="'fin_close_audit_export'" type="primary" @click="exportEvent"
            >导出学生数据</el-button
          >
          <el-button v-if="($route.query.status == 2 || $route.query.status == 3) && $route.query.type == 'edit'" type="primary" @click="dialogAddStuEvent"
            >添加学生</el-button
          >
        </div>
        <div class="search_sList">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="缴费状态">
                <el-select
                  v-model="formInline.status"
                  placeholder="请选择缴费状态"
                  clearable
                  @change="changeFeeStatus"
                >
                  <el-option v-for="(item, key) in $common.statusData" :label="item" :value="key" :key="key"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="pay_detail">
              <div class="pd_item" v-for="(item, index) in payList" :key="index">
                <span class="pdi_title">{{item.label}}:</span>
                <span>{{ orderDetailsData[item.name] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="table_list">
          <el-table
            :data="tableData"
            stripe
            border
            align="center"
            style="width: 100%"
          >
            <el-table-column label="序号" width="55" align="center">
              <template slot-scope="scope">
                {{
                  (formInline.currentPage - 1) * formInline.pageSize +
                  scope.$index +
                  1
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="studentName"
              label="姓名"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="studentNum"
              label="学号"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <span v-if="form.id != scope.row.id">{{scope.row.studentNum}}</span>
                <el-input v-else v-model="form.studentNum"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="学生校验" width="140" align="center">
              <template slot-scope="scope">
                {{ scope.row.errorFlag === 0 ? "正确数据" : "错误数据" }}
              </template>
            </el-table-column>
            <el-table-column prop="unitPrice" label="收费单价" width="100" align="center">
              <template slot-scope="scope">
                {{ scope.row.unitPrice != null ? scope.row.unitPrice : '/' }}
              </template>
            </el-table-column>
            <el-table-column prop="actualUnitPrice" label="实收单价" width="100" align="center">
              <template slot-scope="scope">
                {{ scope.row.actualUnitPrice != null ? scope.row.actualUnitPrice : '/' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="errorMessage"
              label="错误说明"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.errorMessage"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.errorMessage || '/' }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="缴费方式" width="100" align="center">
              <template slot-scope="scope">
                {{ $common.payTypeData[scope.row.payType] || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="缴费状态" width="100" align="center">
              <template slot-scope="scope">
                {{ scope.row.status || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="缴费时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.payDate || '/' }}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="160"
              align="center"
              v-if="$route.query.status == 2 && $route.query.type == 'edit'"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.errorFlag == 1">
                  <el-button
                    type="text"
                    size="small"
                    v-if="form.id == scope.row.id"
                    @click="cancel(scope.row)"
                    >取消</el-button>
                  <el-button
                    type="text"
                    size="small"
                    v-if="form.id == scope.row.id"
                    @click="editEvent(scope.row)"
                    >确认</el-button>
                  <el-button
                    type="text"
                    size="small"
                    v-if="form.id != scope.row.id"
                    @click="auditEvent(scope.row)"
                    >编辑</el-button>
                </template>
                <el-button
                  type="text"
                  size="small"
                  @click="delEvent(scope.row)"
                  >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <dialogDiy
          :isShow="isShow"
          :dialogDiyData="dialogDiyData"
          @dialogEvent="delEvent"
        >
          <div slot="dialogContent" class="dia_opera">
            <img class="errIcon" src="@/assets/img/warn.png" alt="" />
            <p>确定要批量删除该学生信息吗？</p>
            <div class="btn_area">
              <el-button @click="delEvent">关闭</el-button>
              <el-button class="mainBtn" @click="doEvent">确定</el-button>
            </div>
          </div>
        </dialogDiy>

        <!-- 导出学生数据 -->
        <dialogDiy
          :isShow="isShowStu"
          :dialogDiyData="dialogStuDiyData"
          @dialogEvent="dialogStuEvent"
        >
          <div slot="dialogContent" class="diaStu_operas">
            <img class="errIcon" src="@/assets/img/warn.png" alt="" />
            <p>是否导出所有学生数据?</p>
            <div class="btn_area">
              <el-button @click="dialogStuEvent" plain
                >取消</el-button
              >
              <el-button class="mainBtn" @click="exportEvents" plain
                >确认</el-button
              >
            </div>
          </div>
        </dialogDiy>

        <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>

        <batchUpload
          :isShow="isShowUpload"
          :uploadData="uploadData"
          @enterUpload="enterUpload"
          @fileUpload="fileUploadEvent"
        ></batchUpload>

        <dialogDiyNew :isShow="isShowAddStu" :dialogDiyData="dialogAddStuDiyData" @dialogEvent="dialogAddStuEvent">
          <div slot="dialogContent" class="addStu_area">
            <div class="form_add_area">
              <span><span style="color: red;">*</span>学生学号</span>
              <el-input v-model="addForm.studentNum" clearable></el-input>
            </div>
            <div class="btn_area">
              <el-button @click="dialogAddStuEvent" plain
                >取消</el-button
              >
              <el-button class="mainBtn" @click="addStu" plain
                >确认</el-button
              >
            </div>
          </div>
        </dialogDiyNew>
      <!-- </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar> -->
  </div>
</template>

<script>
import Pagination from "../../Pagination";
import publicChildren from "../../publicChildren";
import dialogDiy from '../../commonModule/dialogDiy'
import dialogSubmit from '@/components/commonModule/dialogSubmit'
import batchUpload from '@/components/commonModule/batchUpload'
import dialogDiyNew from '@/components/commonModule/dialogDiyNew'
import way from "@/api/encapsulation";
const OSS = require("ali-oss");
export default {
  name: "closeStudentListUrl",
  components: {
    Pagination,
    publicChildren,
    dialogDiy,
    dialogSubmit,
    batchUpload,
    dialogDiyNew
  },
  props: {
    orderDetailsData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      id: "",
      tableData: [],
      //   currentPage: 1,
      //   pageSize: 10,
      pages: null,
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      formInline: {
        errorFlag: null,
        verifyId: this.$route.query.id,
        currentPage: 1,
        pageSize: 10
      },
      form: {
        id: null,
        studentNum: null,
        verifyId: null
      },
      isShow: false,
      rowData: {},
      dialogDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" },
      },
      isShowStu: false,
      dialogStuDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" },
      },
      isShowSub: false,
      isShowUpload: false,
      uploadData: {
        title: "重新导入学生名单",
        sizeStyle: { width: "600px", height: "300px" },
        moduleName: "学生名单样例",
        labelName: 'noNeed',
      },
      activeName: 'all',
      addForm: {
        studentNum: null,
        verifyId: this.$route.query.id
      },
      payList: [
        {
          label: '学生数量',
          name: 'studentNum'
        },
        {
          label: '应收金额',
          name: 'amountReceivable'
        },
        {
          label: '实收金额',
          name: 'amountActual'
        },
        {
          label: '待收金额',
          name: 'uncollectedAmount'
        }
      ],
      isShowAddStu: false,
      dialogAddStuDiyData: {
        title: '添加学生',
        sizeStyle: {width: '400px',height: '260px'}
      },
    };
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     this.formInline.verifyId = newVal.query.id;
    //   },
    // },
    orderDetailsData: {
      deep: true,
      handler(newVal, oldVal) {
        this.getStudentList()
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,

    //   获取学生名单表格数据
    getStudentList() {
      this.$request.closeStudentList(this.formInline).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total, pages } = res.data.data;
          this.tableData = records;
          this.pages = pages;
          this.childMsg = {
            current,
            size,
            total,
          };
        }
      });
    },
    // 点击切换页数
    changePage(val) {
      // console.log(val);
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getStudentList();
    },
    // 点击按钮筛选
    flagEvent(tab) {
      this.formInline.errorFlag = tab.name == "all" ? "" : tab.name == "error" ? 1 : 0;
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getStudentList();
    },
    cancel() {
      this.clearEvent();
    },
    clearEvent() {
      this.form.id = null;
      this.form.studentNum = null;
      this.form.verifyId = null;
    },
    auditEvent(row) {
      this.form.id = row.id;
      this.form.studentNum = row.studentNum;
      this.form.verifyId = row.closeVerifyId;
    },
    editEvent() {
      if(!this.form.studentNum) {
        this.Warn('学号必填');
        return;
      }
      this.$request.editCloseStudent(this.form).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.clearEvent();
          this.getStudentList();
        }
      })
    },
    delEvent(row = {}) {
      if(row.hasOwnProperty('id')) this.rowData = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      this.$request.deleteCloseStudent({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.getStudentList();
        }
      })
    },
    dialogStuEvent() {
      this.isShowStu = !this.isShowStu
    },
    exportEvent() {
      this.dialogStuEvent()
    },
    // 导出
    exportEvents() {
      let obj = {
        type: 2,
        verifyId: this.$route.query.id
      }
      this.$request.auditStuListexport(obj).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.data || "操作成功")
          this.dialogStuEvent()
          this.isShowSub = true
        }
      });
    },
    closeSub() {
      this.isShowSub = !this.isShowSub;
    },
    fileUploadEvent(val) {
      if (val == "submit") {
        this.closeSub();
      }
      this.isShowUpload = !this.isShowUpload;
    },
    enterUpload(val) {
      let obj = {
        verifyId: this.$route.query.id,
        type: 2,
        admissionBatch: this.orderDetailsData.admissionBatch,
        stationId: this.orderDetailsData.stationId,
        stationName: this.orderDetailsData.stationName,
        tenantId: this.orderDetailsData.tenantId,
        tenantName: this.orderDetailsData.schoolName,
        ...val
      }
      this.$request.stuListAgainExport(obj).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '成功');
          this.fileUploadEvent("submit");
        }
      });
    },
    dialogAddStuEvent() {
      this.isShowAddStu = !this.isShowAddStu
      if(!this.isShowAddStu) this.addForm.studentNum = null
    },
    addStu() {
      if (!this.addForm.studentNum) {
        this.Warn('请填写学生学号')
        return;
      }
      this.$request.addVerifyStudentNames(this.addForm).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.formInline.currentPage = 1
          this.dialogAddStuEvent()
          this.getStudentList()
          this.$parent.getAudioDetail()
        }
      })
    },
    changeFeeStatus() {
      if(this.formInline.status === '') this.formInline.status = null
      this.getStudentList()
    }
  },
};
</script>
 
<style lang="less" scoped>
.closeStudentListUrl {
  background-color: #fff;
  height: 100%;
  padding-bottom: 20px;
  .elScroll {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
.head_button {
  // padding: 20px 40px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  /deep/ .el-tabs {
    display: inline-block;
    margin-right: 20px;
  }
  /* .el-button {
    border-color: #66b1ff;
  } */
}
/* /deep/.el-button {
  width: 150px;
  background-color: #1282ff !important;
  color: #fff !important;
} */
.search_sList {
  padding: 0!important;
}
.table_list {
  box-sizing: border-box;
  background-color: #fff;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.el-pagination {
  text-align: right;
}
.diaStu_operas {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  .errIcon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
.top_opera {
  /deep/ .el-form {
    flex: 1!important;
    // display: flex;
    // align-items: center;
  }
  .pay_detail {
    flex: 2;
    display: flex;
    margin-top: 10px;
    .pd_item {
      margin-right: 20px;
      font-size: 14px;
    }
  }
}
.addStu_area {
  padding: 30px;
  .form_add_area {
    display: flex;
    align-items: center;
    font-size: 16px;
    & > span:first-of-type {
      display: inline-block;
      width: 100px;
    }
  }
  .btn_area {
    margin-top: 40px;
    text-align: center;
  }
}
</style>